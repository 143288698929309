/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Edge = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        //sliders autoplay
    	//intro slider
    	$('#carousel_fade_intro').carousel({
    		interval: 2500,
    		pause: "false"
    	});

    	//works sliders
    	$('#carousel_horizontal_slide, #carousel_vertical_slide, #carousel_fade_1, #carousel_fade_2').carousel({
    		interval: false
    	});

        //make section height of window
    	$(function(){
    		$('#intro').css({'height':($(window).height()) + 'px'});

    		$(window).resize(function(){
                $('#intro').css({'height':($(window).height()) + 'px'});
    		});
    	});

        //custom scrollbar
        $(document).ready(function() {
        	$("html").niceScroll();
        });

        //stellar paralax scrolling
        $(document).ready(function() {
            $.stellar({
                scrollProperty: 'scroll',
        		positionProperty: 'position',
        		horizontalScrolling: false,
        		verticalScrolling: true,
        		horizontalOffset: 0,
        		verticalOffset: 0,
        		responsive: true,
            });
        });

        //contact form
        function showRequest(formData, jqForm, options) {
            var queryString = $.param(formData);
        	return true;
        }

        function showResponse(responseText, statusText)  {
        }

        $(document).ready(function() {
            var options = {
                target: '.alert',
        	    beforeSubmit: showRequest,
                success: showResponse
        	};

        	$('#contactForm').ajaxForm(options);
        });

        $.fn.clearForm = function() {
            return this.each(function() {
                var type = this.type, tag = this.tagName.toLowerCase();

        		if (tag === 'form') {
        			return $(':input',this).clearForm();
                }

        		if (type === 'text' || type === 'password' || tag === 'textarea') {
        			this.value = '';
                }
        		else if (type === 'checkbox' || type === 'radio') {
        			this.checked = false;
                }
        		else if (tag === 'select') {
        			this.selectedIndex = -1;
                }
        	});
        };

        //smooth scroll on page
        $(function() {
            $('#more a, .nav a, .nav li a, .brand, #footer li a, a.smooth-scroll').bind('click',function(event){
                var $anchor = $(this);

                $('[data-spy="scroll"]').each(function () {
                    var $spy = $(this).scrollspy('refresh');
                });

                $('html, body').stop().animate({
                    scrollTop: $($anchor.attr('href')).offset().top - 61
                }, 1500, 'easeInOutExpo');
                event.preventDefault();
            });
        });

        //gallery image hover tooltip trigger
        $("[data-thumb=tooltip]").tooltip();

        //collapse menu on click on mobile and tablet devices
        $('.nav a').click(function () {
            $(".nav-collapse").collapse("hide");
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        $("#status").fadeOut();
        $("#preloader").delay(350).fadeOut("slow");


        $(document).ready(function(){
            var hashSplit = window.location.href.split('#');
            if (hashSplit.length > 1) {
                $('html, body').stop().animate({
                    scrollTop: $('#' + hashSplit[1]).offset().top - 61
                }, 1500, 'easeInOutExpo');
            }

            // remove fragment as much as it can go without adding an entry in browser history:
            window.location.replace("#");

            // slice off the remaining '#' in HTML5:
            if (typeof window.history.replaceState === 'function') {
              history.replaceState({}, '', window.location.href.slice(0, -1));
            }
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Edge;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
